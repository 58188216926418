body {
   
    min-height: 100vh;



    
  }
  
  #button-addon1 {
    color: #ffc371;
  }
  
  i {
    color: #ffc371;
  }
  
  .form-control::placeholder {
    font-size: 0.95rem;
    color: #aaa;
    font-style: italic;
  }
  
  .form-control.shadow-0:focus {
    box-shadow: none;
  }
  
  .spanFtr{
    color: rgb(142, 147, 218);
    cursor: pointer;
  }