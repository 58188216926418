
.Dash{
  background: linear-gradient(60deg, #736AFF, #AA6DEB);
}

.demandeListScroll{
  overflow: auto;
}

.reviewListScroll{
  overflow: auto;
}

.divDemandes{
  /* text-align: left; */
  color: #6e6f79;
  font-family: MontserratM;
  display: flex;
  align-items: center;
}
.divDemandes:hover{
    background: #6200ee;
  color: #fff;
  border-radius: 8px;
}


.divList{
  text-align: left;
  color: #6e6f79;
  font-family: MontserratM;
}
.divList:hover{
  background: #6200ee;
  color: #fff;
  border-radius: 8px;
}
.divList:hover>i {
  color: #fff;
}

.divAjouterCar{
  text-align: left;
  color: #6e6f79;
  font-family: MontserratM;
}
.divAjouterCar:hover{
    background: #6200ee;
  color: #fff;
  border-radius: 8px;
}

.divMessages{
  text-align: left;
  color: #6e6f79;
  font-family: MontserratM;
}
.divMessages:hover{
    background: #6200ee;
  color: #fff;
  border-radius: 8px;
}

.divReviews{
  text-align: left;
  color: #6e6f79;
  font-family: MontserratM;
}
.divReviews:hover{
  background: #6200ee;
  color: #fff;
  border-radius: 8px;
}


.divProfil:hover{
    background: #6200ee;
  
}



.cars__center{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.cars__center .car{
  margin: 0rem;
}


@media screen and (max-width: 1000px) {
  .car{
      margin: 1rem auto;
  }
  
}

.small__device{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

}

.small__device > div{
  width: max-content;
  width: 18vw;
  display: grid;
  height: 100%;
}

.sign__out{
  margin: 1rem;
  border: none;
  outline: none;
  background-color: red;
  color: white;
  border-radius: 1rem;
  padding: 0.2rem 0.5rem;
}

/* .notification::after{
  content: attr(count);
  background-color: red;
  padding: 0.1rem 0.5rem;
  color: white;
  border-radius: 1rem;
  position: relative;
  bottom: 2rem;
  font-weight: 900;
  font-size: small;
  width: max-content;
} */

.notification::after {
  content: attr(count);
  background-color: red;
  padding: 0.1rem 0.5rem;
  color: white;
  border-radius: 1rem;
  position: relative;
  bottom: 2rem;
  font-weight: 900;
  font-size: small;
  width: max-content;
  display: inline-block; /* Ensure the pseudo-element is displayed as an inline block */
}

.notification[count='0']::after {
  display: none; /* Hide the pseudo-element if count attribute is 0 */
}



.big__screen{
  display: grid;
}

.big__screen > div{
  display: grid;
  position: relative;

}

.big__screen > .notification::after{
  position: absolute;
  right: 0.1rem;
}

.fix__logout{
  position: fixed;
  inset: 0.5rem auto auto 0.5rem;
  width: 6rem !important;
  z-index: 100;
}

.small__device > div::after{
  position: relative;
  left: 1.5rem;
}

