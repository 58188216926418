/* .div_CarNameRevewPrix{

  min-height: 700px;
  @media (max-width: 960px) {
    flex-wrap: wrap;
  }
} */

.car__image{
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 0.1rem solid rgb(143, 144, 219);
  overflow: hidden;
  display: grid;
  place-items: center;
}

.car__image > img{
  width: auto;
  height: 100%;
}

.more__cars{
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.5rem;
}

.divImage img{
  width: 100%;
  height: auto;
  border-radius: 0.5rem;
}

@media (max-width: 767px) {
  .return__date{
    margin-top: 1.5rem !important;
  }

  .prixCar {
    width: 96% !important;
    margin: auto !important;
  }
}

@media (min-width: 767px) {
  .wrapper__input{
    display: grid;
    grid-template-columns: 50% 50%;
    /* gap: 0.5rem; */
    max-width: 100%;
  }

  .date__input{
    width: 48% !important;
  }

  .return__date{
    position: relative;
    left: 1rem;
  }

  .included__wrapper ul{
    transform: translateX(2rem)
  }
}


.included__wrapper{
  padding: 1rem;
  margin-bottom: 1rem;
  position: relative;
}



.Included {
  position: relative; 
  display: inline-block; 
  z-index: 1;
}

.Included::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 4px;
  width: 100%;
  height: 8px; 
  background-color: #43e5a0;
  z-index: -1;
}


.Excluded{
  position: relative; 
  display: inline-block; 
  z-index: 1;
}


.Excluded::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 4px;
  width: 100%;
  height: 8px; 
  background-color: #e74c3c;
  z-index: -1;
}




