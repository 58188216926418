.login,
.image {
  min-height: 100vh;
}

.bg-image {
  background-image: url('../../assets//img/imgLoginV3.jpg');
  background-size: cover;
  background-position: center center;
}

.go__home{
  color: rgb(113, 77, 197);
  cursor: pointer;
  font-size: large;
}


.inpt{
  border-radius: 12.5px;
  background-color: #fff
  
 
}

.bottonLogin {

width: 100%;
border-radius:12.5px ;
}

.form-check-input {
  border-radius: 3px;
}

.form-check-input:checked {
  background-color: #6415ff;
  border-color: #6415ff;
  border-radius: 3px;
}

@media (min-width: 576px) { 
  .divGaucheLogin {
      display: none;
    }
    
}