.cars__center {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.cars__center .car {
    margin: 1rem;
}


@media screen and (max-width: 970px) {
    .car {
        margin: 1rem auto;
    }

    .gNYPSp {
        position: absolute !important;
        left: 50% ;
        transform: translateX(-50%);
        bottom: 3rem !important;
        height : '56px' !important;
        width:50% !important;
        /* padding:'15px 30px 15px 30px'!important; */
        font-family:'Montserrat' !important;
    }
}

.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: max-content;
    gap: 1rem;
}

.right svg,
.left svg {
    font-size: 2rem;
    cursor: pointer;
}

.page,
.selected__page {
    background-color: #d8cdff;
    color: #fff;
    border-radius: 50%;
    padding: 0.0rem 0.5rem;
    font-weight: 300;
    font-size: 1rem;
    cursor: pointer;
}

.selected__page {
    background-color: #6415ff;
    color: #fff;
}


@media (max-width: 768px) {
     .welcome-text {
      display: block; /* Make "Welcome" its own block */
      background: linear-gradient(180deg,rgba(255,255,255,0) 70%, rgb(142, 147, 218) 50%);
      width: fit-content;
      color: #212529;
    }
  }


  @media (max-width: 960px) {

    .header {
        padding: 15px 0 50px 0 !important;
        text-align: left !important;
        max-width: 100% !important;
        font-size: 1rem !important;
    }
    
  }