.react-datepicker {
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 0.9rem;
    background-color: transparent !important;
    color: #f0f !important;
    border: none !important;
    border-radius: 0rem !important;
    display: inline-block;
    position: relative;
    line-height: initial;
  }
  
  
  .birth .react-datepicker__month-dropdown-container select,.birth .react-datepicker__year-dropdown-container select {
    border: 2px solid #4a20aa;
    border-radius: 18px;
    color: #4a20aa;
    padding: 5px;
    text-align: center
  }
  
  .birth .react-datepicker__month-dropdown-container {
    padding-right: 4px
  }
  
  .react-datepicker__month-container {
    text-align: center
  }
  
  .react-datepicker .react-datepicker__aria-live {
    display: none
  }
  
  
  
  .react-datepicker.ar .react-datepicker__navigation--next,.react-datepicker.ar .react-datepicker__navigation--previous {
    position: absolute !important;
    border: unset !important;
    width: 20px !important;
    height: 20px !important;
    text-indent: -999em !important;
    background-image: url(../../../assets/svg/right-arrow.svg) !important;
    background-position: center center !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-color: transparent !important;
    z-index: 444;
  }
  
  .react-datepicker.ar .react-datepicker__navigation--previous {
    left: 3.125rem !important;
    right: 3rem !important;
    transform: rotate(0) !important;
  }
  
  .react-datepicker.ar .react-datepicker__navigation--next {
    left: 3.125rem !important;
    right: auto !important;
    transform: rotate(180deg) !important;
  }
  
  .react-datepicker__navigation--next,.react-datepicker__navigation--previous {
    position: absolute !important;
    border: unset !important;
    width: 20px !important;
    height: 20px !important;
    text-indent: -999em !important;
    background-image: url(../../../assets/svg/right-arrow.svg) !important;
    background-position: center center !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-color: transparent !important;
    z-index: 444;
  }
  
  .react-datepicker__navigation--previous {
    left: 3.125rem !important;
    transform: rotate(180deg) !important;
  }
  
  .react-datepicker__navigation--next {
    right: 3.125rem !important;
  }
  
  .react-datepicker__navigation-icon::before {
    border-color: transparent !important;
    border-style: solid !important;
    border-width: 3px 3px 0 0;
    content: "";
    display: block;
    height: 9px;
    position: absolute;
    top: 6px;
    width: 9px;
  }
  
  /* .react-datepicker__header {
    background-color: transparent;
    border-bottom: unset;
  } */
  
  .react-datepicker__header {
    text-align: center !important;
    background-color: transparent !important;
    border-bottom: none !important;
    border-top-left-radius: 0.3rem !important;
    padding: 8px 0 !important;
    position: relative !important;
    font-family: MontserratB;
  }
  
  .react-datepicker__header__dropdown {
    margin-bottom: 1.25rem !important
  }
  
  .react-datepicker__current-month {
    color: #230077 !important;
    padding-bottom: 20px !important;
    line-height: 18px !important;
    font-size: 1.125rem !important;
    font-family: MontserratB !important;
  }
  
  .react-datepicker__month,.react-datepicker__year {
    margin: 0
  }
  
  .react-datepicker__month-container,.react-datepicker__year-container {
    width: 100%
  }
  
  .react-datepicker__month-read-view--selected-month,.react-datepicker__month-read-view--selected-year,.react-datepicker__year-read-view--selected-month,.react-datepicker__year-read-view--selected-year {
    color: #4a20aa;
    margin-right: .625rem
  }
  
  .react-datepicker__month-read-view--down-arrow,.react-datepicker__year-read-view--down-arrow {
    border-top-color: #4a20aa
  }
  
  .react-datepicker__month-read-view--down-arrow:before,.react-datepicker__year-read-view--down-arrow:before {
    border-color: #4a20aa
  }
  
  .react-datepicker__month-read-view--down-arrow:hover,.react-datepicker__year-read-view--down-arrow:hover {
    border-top-color: #4a20aa!important
  }
  
  .react-datepicker__month-read-view:hover--down-arrow,.react-datepicker__year-read-view:hover--down-arrow {
    border-top-color: #4a20aa!important
  }
  
  .react-datepicker__month-dropdown,.react-datepicker__year-dropdown {
    background-color: #4a20aa;
    border-radius: .9375rem;
    border: none;
    padding: .9375rem;
    z-index: 10
  }
  
  .react-datepicker__month-dropdown-container,.react-datepicker__year-dropdown-container {
    display: inline-block
  }
  
  .react-datepicker__month-option,.react-datepicker__year-option {
    color: #fff;
    padding: .3125rem
  }
  
  .react-datepicker__month-option:hover,.react-datepicker__year-option:hover {
    background-color: #4a20aa
  }
  
  .react-datepicker__week {
    display: flex;
    /* -webkit-flex-flow:row; */
    /* justify-content:space-around; */
    padding: 7px 0;
  }
  
  .react-datepicker__day {
    position: relative !important;
    flex: 1 !important;
    height: auto!important;
    color: #6614ff !important;
    z-index: 2 !important;
  }
  
  /* .react-datepicker__day-names {
    display: flex
  } */
  
  .react-datepicker__day-name {
    flex: 1 !important;
    text-transform: uppercase !important;
    color: #230077 !important;
    letter-spacing: 1px !important;
    font-size: .75rem !important;
  }
  
  .react-datepicker__day--outside-month {
    color: #7c52de
  }
  
  .react-datepicker__day--disabled {
    color: #bda7ef
  }
  
  .react-datepicker__day:before {
    content: "";
    position: absolute;
    width: 1.875rem;
    height: 1.875rem;
    /* background-color: transparent; */
    border: 1px solid transparent;
    border-radius: 50%;
    z-index: -1;
    transition: all .1s linear;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%)
  }
  
  .react-datepicker__day--selected,.react-datepicker__day:hover:not(.react-datepicker__day--disabled) {
    background-color: transparent;
    color: #fff;
    cursor: pointer
  }
  
  .react-datepicker__day--selected:before,.react-datepicker__day:hover:not(.react-datepicker__day--disabled):before {
    background-color:  #e9e4ff;
  }
  
  .react-datepicker__calendar--dark .react-datepicker__day--selected:before,
  .react-datepicker__calendar--dark .react-datepicker__day:hover:not(.react-datepicker__day--disabled):before {
    background-color:  #FBD38D;
  }
  
  .react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
    border-top-color: #4a20aa
  }
  
  
  
  .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    border-radius: 0.3rem;
    background-color: transparent !important; 
    border-radius: 50% ;
    /* color: rgb(0, 0, 0) !important;  */
  }
  
  
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color: transparent !important;
    color: #fff;
  }
  
  
  .react-datepicker__day,
  .react-datepicker__time-name {
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
    font-family: Montserrat;
  }
  
  .react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
    color: #ccc !important;
    pointer-events: none !important;
  } 
  
  .react-datepicker__calendar--dark .react-datepicker__day-name,
    .react-datepicker__calendar--dark .react-datepicker__current-month {
    color: #fff !important;
  }


  .react-datepicker__month,.react-datepicker__year {
    margin: 0 !important;
    direction: ltr !important;
  }