.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    margin: auto;
  }

  .abs{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  .loader {
    border: 0.3rem solid rgba(0, 0, 0, 0.1);
    border-top-color: #6415FF;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    animation: spin 1s ease-in-out infinite;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

  