.blockquote-custom {
    position: relative;
    font-size: 1.1rem;
  }
  
  .blockquote-custom-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -25px;
    left: 50px;
  }

.reviews{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
}

.reviews > .review{
  min-width: 400px;
  background-color: rgb(15, 15, 116);
  color: white;
  border-radius: 1rem;
  padding: 2rem;
  margin: auto;
  height: 40vh;
  overflow-y: auto;
}

@media screen and (max-width: 400px) {
  .reviews{
    display: grid;
  }

  .review{
    width: 90vw !important;
  }
}




