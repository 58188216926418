.unauthorized{
    display: grid;
    place-items: center;
    grid-template-columns: auto auto;
    min-height: 90vh;
    padding: 4vw;
}

.links{
    display: grid;
    place-items: center;
    width: 60%;
}


.unauthorized > img{
    width: 60%;
    height: auto;
}

/* p{
    font-size: 2rem;
    font-weight: 800;
} */

@media only screen and (max-width: 600px) {
    /* CSS rules to apply when the screen width is less than or equal to 600 pixels */
    .unauthorized{
        display: grid;
        grid-template-columns: 1fr;
    }
  }
  