.heading,
.heading span {
    text-transform: uppercase;
    line-height: 98%;
    color: #fff;
    letter-spacing: .05rem;
}

.heading span {
    color: #7620FF;
}

.large {
    font-size: clamp(2.5rem, 6vw, 5rem);
}

.medium {
    font-size: clamp(2.3rem, 5vw, 3.5rem);
}

.medium--dark {
    font-size: clamp(2.3rem, 5vw, 3.5rem);
    color: var(--secondary);
}

.small {
    font-size: clamp(1.5rem, 5vw, 2rem);
}

.small--dark {
    font-size: clamp(1.5rem, 5vw, 2rem);
    color: var(--secondary);
}