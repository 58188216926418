.cards{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  gap: 2rem;
}

.cards > div{
  min-width: 80%;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  width: 5px;
  /* background: #f5f5f5; */
}

::-webkit-scrollbar-thumb {
  width: 1em;
  background-color: #ddd;
  /* outline: 1px solid slategrey; */
  border-radius: 1rem;
}

.css-1qzevvg{
position: relative;
margin: 0rem auto 0px !important;;
}

.css-1qzevvg > img{
/* position: absolute; */
height: 10rem;
width: auto;
top: 3rem;
left: 50%;
transform: translateX(-100%);
color: #fff;

}

.carousel{
padding-bottom: 1rem;
}


.custom-carousel .carousel__arrow {
  color: red; /* Change the arrow color */
  font-size: 24px; /* Adjust the size if needed */
}
