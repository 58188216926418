/* body {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #d0e, #91f);
  } */
  
  .containerCar {
    width: 150px;
    height: 150px;
    display: grid;
    overflow: hidden;
    margin: 0;
    list-style: none;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 15px;
    padding: 15px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50px;
  }
  
  .item {
    background: white;
    border-radius: 100%;
  }
  