.img-fluid {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease; /* Ajoute une transition à la propriété transform */
  }
  
  /* Effet de mise à l'échelle lors du survol de l'image */
  .img-fluid:hover {
    transform: scale(1.1);
  }


  .img-fluid2 {
    width: 70%;
    height: auto;
  }
  
  /* Effet de mise à l'échelle lors du survol de l'image */
  .img-fluid2:hover {
    transform: scale(1.1);
  }

