
/* Plus Jakarta Display font */

@font-face{
  font-family: jakR;
  src: url(./assets/fonts/jakarta/PlusJakartaDisplay-Regular.woff2);
}

.jakR{
  font-family: jakR;
}

@font-face{
  font-family: jakM;
  src: url(./assets/fonts/jakarta/PlusJakartaDisplay-Medium.woff2);
}

.jakM{
  font-family: jakM;
}

@font-face{
  font-family: jakB;
  src: url(./assets/fonts/jakarta/PlusJakartaDisplay-Bold.woff2);
}

.jakB{
  font-family: jakB;
}
/* /////////////////////// */



@font-face{
  font-family: Lato;
  src: url(./assets/fonts/Lato/Lato-Regular.ttf);
}

.Lato{
  font-family: Lato;
}


@font-face{
  font-family: LatoL;
  src: url(./assets/fonts/Lato/Lato-Light.ttf);
}

.LatoL{
  font-family: LatoL;
}

@font-face{
  font-family: LatoT;
  src: url(./assets/fonts/Lato/Lato-Thin.ttf);
}

.LatoT{
  font-family: LatoT;
}

@font-face{
  font-family: LatoB;
  src: url(./assets/fonts/Lato/Lato-Bold.ttf);
}

.LatoB{
  font-family: LatoB;
}

@font-face{
  font-family: LatoK;
  src: url(./assets/fonts/Lato/Lato-Black.ttf);
}

.LatoK{
  font-family: LatoK;
}


@font-face{
  font-family: Montserrat;
  src: url(./assets/fonts/Montserrat-Regular.ttf);
}

.Montserrat{
  font-family: Montserrat;
}


@font-face{
  font-family: MontserratL;
  src: url(./assets/fonts/Montserrat-Light.ttf);
}

.MontserratL{
  font-family: MontserratL;
}

@font-face{
  font-family: MontserratM;
  src: url(./assets/fonts/Montserrat-Medium.ttf);
}

.MontserratM{
  font-family: MontserratM;
}

@font-face{
  font-family: MontserratB;
  src: url(./assets/fonts/Montserrat-Bold.ttf);
}

.MontserratB{
  font-family: MontserratB;
}

@font-face{
  font-family: MontserratK;
  src: url(./assets/fonts/Montserrat-Black.ttf);
}

.MontserratK{
  font-family: MontserratK;
}




/* cairo */


@font-face{
  font-family: cairoR;
  src: url(./assets/fonts/cairo/Cairo-Regular.ttf);
}

.cairoR{
  font-family: cairoR;
}

@font-face{
  font-family: cairoM;
  src: url(./assets/fonts/cairo/Cairo-Medium.ttf);
}

.cairoM{
  font-family: cairoM;
}

@font-face{
  font-family: cairoS;
  src: url(./assets/fonts/cairo/Cairo-SemiBold.ttf);
}

.cairoS{
  font-family: cairoS;
}

@font-face{
  font-family: cairoB;
  src: url(./assets/fonts/cairo/Cairo-Bold.ttf);
}

.cairoB{
  font-family: cairoB;
}

.CarListScroll{
  overflow: auto;
}


body {
  margin: 0;
  font-family: 'Montserrat',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

code {
  font-family: 'Montserrat',source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;

    
}

.yellow{
  color:'#F2B300';
}
.indigo{
  color:'#6610f2';
}


.slick-prev:before, .slick-next:before {
   font-family: 'slick' !important;
  font-size: 24px !important;
  line-height: 1 !important;
  opacity: 1 !important;
  color: #212529 !important;
  -webkit-font-smoothing: antialiased !important;
}


.css-5jdg10{
  

}

.css-1ydjfm6[aria-checked=true], .css-1ydjfm6[data-checked] {
  background-color: #fecd07 !important;
  border-color: #fecd07 !important;
  color: #06837f !important;
}

.css-1ydjfm6{
  background-color: #E2E8F0 !important;
}



.leaflet-container .leaflet-control-attribution {
	cursor: auto;
  display: none !important;
	}




  p {
    margin-bottom: 0 !important;
  }
  


/* menu langue */
  .css-1kfu8nn {
      min-width: unset !important;
  }


  .css-1xa1gs2{
    color:#808080;
  }



  .css-p80um5 {
  width: 90%;
  }

  .css-26wpki {
    width: 90%;
  }